import React, { useState } from "react"
import { FaUser, FaEnvelope, FaPhoneAlt, FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"
import PrivacyPolicy from "../components/privacy-policy.component"

import "./contact.styles.scss"

const ContactPage = ({ location }) => {
  const [policy, setPolicy] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = () => {
    setModalOpen(false)
  }

  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      closeModal()
    }
  }

  return (
    <Layout className="contact" pathname={location.pathname}>
      <Seo
        title="Contact Me"
        description="Contact Dan Parasky to request information about courses, discuss a consultation or schedule a speaking engagement."
      />
      <section className="hero contact__hero is-medium">
        <div className="container container--text">
          <div className="hero-body is-flex is-flex-direction-column is-align-items-center has-text-centered">
            <h1 className="title is-size-2-mobile is-size-1-tablet">
              Have a Question?
            </h1>
            <h2 className="subtitle mt-4">
              Contact me to request information about my courses, discuss a consultation or schedule a speaking engagement.
            </h2>
          </div>
        </div>
      </section>
      <section className="contact__form">
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <form
                method="post"
                action="/success?type=contact"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Contact Dan Parasky"
                className="contact-form"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contact Dan Parasky" />
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="student">
                        Name
                      </label>
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          placeholder="First Last"
                          name="name"
                          required
                        />
                        <span className="icon is-small is-left">
                          <FaUser />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email Address
                      </label>
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="email"
                          placeholder="email@example.com"
                          name="email"
                          required
                        />
                        <span className="icon is-small is-left">
                          <FaEnvelope />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="phone">
                        Mobile Phone Number
                      </label>
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          placeholder="123-456-7890"
                          name="phone"
                        />
                        <span className="icon is-small is-left">
                          <FaPhoneAlt />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <label className="label" htmlFor="comments">
                        Questions/Comments
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          placeholder="Any questions or comments"
                          name="comments"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal pt-4">
                  <div className="field-body">
                    <div className="field is-flex is-align-items-center">
                      <div className="control has-text-left">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="policy"
                            checked={policy}
                            onChange={() => setPolicy(!policy)}
                            required
                          />{" "}
                          I have read and agree to the{" "}
                          <button
                            className="button is-ghost"
                            style={{ height: "auto", lineHeight: 1, padding: 0 }}
                            onClick={() => setModalOpen(true)}
                          >
                            privacy policy
                          </button>
                        </label>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control has-text-left-mobile has-text-right-tablet">
                        <button className="button is-link button--send">Send <FaArrowRight /></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`modal ${modalOpen ? "is-active" : ""}`}
                  style={{ zIndex: 1000 }}
                >
                  <div className="modal-background" onClick={closeModal} onKeyDown={handleKeyDown} role="presentation"></div>
                  <div className="modal-card">
                    <header className="modal-card-head">
                      <p className="modal-card-title"></p>
                      <button
                        className="delete"
                        aria-label="close"
                        onClick={closeModal}
                      ></button>
                    </header>
                    <section className="modal-card-body">
                      <PrivacyPolicy />
                    </section>
                    <footer className="modal-card-foot is-justify-content-space-between">
                      <button className="button" type="button" onClick={closeModal}>
                        Cancel
                      </button>
                      <button
                        className="button is-success"
                        type="button"
                        onClick={() => {
                          setPolicy(true)
                          closeModal()
                        }}
                      >
                        Accept
                      </button>
                    </footer>
                  </div>
                </div>
              </form>
            </div>
            <div className="column is-5 is-flex is-flex-direction-column is-justify-content-center">
              <div className="content is-size-6 contact__info">
                <h3 className="is-size-5 has-text-primary">Build Community.<br/>Create Profit.<br/>Get Organized.</h3>
                <p>Let's work together and explore how to enhance your private music studio offerings.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default ContactPage
